import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";

function App() {
  return (
    <>
      {/* =========================== admin dashboard =======================*/}
      <BrowserRouter>
        <Routes>
          <Route
            path="admin"
            element={
              // <AdminPrivateRoute>
              <Admin />
              // </AdminPrivateRoute>
            }
          >
            <Route index element={<AdminDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
