import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import * as HiIcons from "react-icons/hi";
import * as IoIcons from "react-icons/io5";
import * as AiIcons from "react-icons/ai";

import { Link, Outlet, useNavigate } from "react-router-dom";
import { Collapse, Container } from "react-bootstrap";
import "./AdminContent.css";
import AdminFooter from "./AdminFooter";
const AdminContent = () => {
	//test
	const openClose = (id) => {
		const mId = id;
	};
	const [toggleNav, setToggleNav] = useState(false);
	const [openOne, setOpenOne] = useState(false);
	const [openTwo, setOpenTwo] = useState(false);
	const [openThree, setOpenThree] = useState(false);
	const [openFour, setOpenFour] = useState(false);
	const [openFive, setOpenFive] = useState(false);
	const [openSix, setOpenSix] = useState(false);

	return (
		<div className="d-flex admin__dashboard">
			<nav className={`admin_sidebar ${toggleNav ? "close" : ""}`}>
				<header className="mt-4 mb-5">
					<Link to="/" target="_blank" className="text-decoration-none">
						<div className="mx-4 image-text d-flex align-items-center justify-content-between">
							<span className="image">
								{/* <img src={require("../../Assets/icon.png")} alt="" /> */}
							</span>
							<div className="text logo-text ">
								<span className="name fs22">Trodad</span>
							</div>
						</div>
					</Link>

					<FaIcons.FaChevronCircleRight
						className="bx bx-chevron-right toggle"
						onClick={() => setToggleNav(!toggleNav)}
					/>
				</header>

				<div className="menu-bar">
					<div className="menu">
						<ul className="menu-links ps-0">
							<li className="nav-link">
								<Link to="">
									<AiIcons.AiFillDashboard className="icon" />
									<span className="text nav-text">Dashboard</span>
								</Link>
							</li>
							<li className="nav-link">
								<Link to="our-work">
									<BiIcons.BiMenu className="icon" />
									<span className="text nav-text">Our Work</span>
								</Link>
							</li>

							<li className="nav-link">
								<Link to="quick-services">
									<BsIcons.BsSliders className="icon" />
									<span className="text nav-text">Quick Services</span>
								</Link>
							</li>

							<li className="nav-link">
								<Link to="top-services">
									<HiIcons.HiUserGroup className="icon" />
									<span className="text nav-text">Top Services</span>
								</Link>
							</li>

							<li
								className="nav-link d-flex align-items-center cursor"
								id="latestProjects"
								onClick={() => setOpenOne(!openOne)}
							>
								<div className="d-flex justify-content-between align-items-center py-2">
									<FaIcons.FaProductHunt className="icon" />
									<div className="text product_dropdown">
										Latest Projects
										<span>
											<IoIcons.IoChevronDownOutline
												className={` ms-4 ${openOne ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>

							<div className="product_dropdown_item w-100">
								<Collapse id="latestProjectsCollapse" in={openOne}>
									<div id="">
										<Link to="">
											<p className="text nav-text mb-0  py-2">
												Project Category
											</p>
										</Link>
										<Link to="">
											<p className="text nav-text mb-0 py-2">All Projects</p>
										</Link>
									</div>
								</Collapse>
							</div>

							<li
								className="nav-link d-flex align-items-center cursor"
								id="Clients"
								onClick={() =>  setOpenTwo(!openTwo)}
							>
								<div className="d-flex justify-content-between align-items-center py-2">
									<FaIcons.FaProductHunt className="icon" />
									<div className="text product_dropdown">
										Clients
										<span>
											<IoIcons.IoChevronDownOutline
												className={`ms-4 ${openTwo ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>
							<div className="product_dropdown_item w-100">
								<Collapse id="ClientsCollapse" in={openTwo}>
									<div id="example-collapse-text">
										<Link to="">
											<p className="text nav-text mb-0 py-2">Manage Client</p>
										</Link>
										<Link to="">
											<p className="text nav-text mb-0 py-2">Review</p>
										</Link>
									</div>
								</Collapse>
							</div>

							<li
								className="nav-link d-flex align-items-center cursor"
								id="Carrers"
								onClick={() =>   setOpenThree(!openThree)}
							>
								<div className="d-flex justify-content-between align-items-center py-2">
									<FaIcons.FaUserTie className="icon" />
									<div className="text product_dropdown">
										Carrers
										<span>
											<IoIcons.IoChevronDownOutline
												className={`ms-4 ${openThree ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>
							<div className="product_dropdown_item w-100">
								<Collapse id="CareersCollapse" in={openThree}>
									<div id="example-collapse-text">
										<Link to="">
											<p className="text nav-text mb-0 py-2">Departments</p>
										</Link>
										<Link to="">
											<p className="text nav-text mb-0 py-2">Locations</p>
										</Link>
										<Link to="career/explore-openings">
											<p className="text nav-text mb-0 py-2">
												Explore Openings
											</p>
										</Link>
										<Link to="">
											<p className="text nav-text mb-0 py-2">
												Job Applications
											</p>
										</Link>
									</div>
								</Collapse>
							</div>
							<li
								className="nav-link d-flex align-items-center cursor"
								id="Settings"
								onClick={() =>  setOpenFour(!openFour)}
							>
								<div className="d-flex justify-content-between align-items-center py-2">
									<FiIcons.FiSettings className="icon" />
									<div className="text product_dropdown">
										Settings
										<span>
											<IoIcons.IoChevronDownOutline
												className={`ms-4 ${openFour ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>
							<div className="product_dropdown_item w-100">
								<Collapse id="SettingsCollapse" in={openFour}>
									<div id="example-collapse-text">
										<Link to="">
											<p className="text nav-text mb-0 py-2">Maintenance</p>
										</Link>
									</div>
								</Collapse>
							</div>

							<li
								className="nav-link d-flex align-items-center"
								id="Blogs"
								onClick={() =>  setOpenFive(!openFive)}
							>
								<div className="d-flex justify-content-between align-items-center py-2">
									<FaIcons.FaBlogger className="icon" />
									<div className="text product_dropdown">
										Blogs
										<span>
											<IoIcons.IoChevronDownOutline
												className={`ms-4 ${openFive ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>
							<div className="product_dropdown_item w-100">
								<Collapse id="BlogsCollapse" in={openFive}>
									<div id="example-collapse-text">
										<Link to="">
											<p className="text nav-text mb-0 py-2">Category</p>
										</Link>
										<Link to="">
											<p className="text nav-text mb-0 py-2">Posts</p>
										</Link>
									</div>
								</Collapse>
							</div>

							<li className="nav-link d-flex align-items-center">
								<div
									onClick={() => setOpenSix(!openSix)}
									aria-controls="example-collapse-text"
									aria-expanded={openSix}
									className="d-flex justify-content-between align-items-center py-2"
								>
									<BiIcons.BiSupport className="icon" />
									<div className="text product_dropdown">
										Support
										<span>
											<IoIcons.IoChevronDownOutline
												className={`ms-4 ${openSix ? "rotate_icon" : ""}`}
											/>
										</span>
									</div>
								</div>
							</li>
							<div className="product_dropdown_item w-100">
								<Collapse in={openSix}>
									<div id="example-collapse-text">
										<Link to="">
											<p className="text nav-text mb-0 py-2">Visitors</p>
										</Link>
									</div>
								</Collapse>
							</div>

							<li className="nav-link">
								<Link to="feedback">
									<HiIcons.HiUserGroup className="icon" />
									<span className="text nav-text">Feedback</span>
								</Link>
							</li>

							<li className="nav-link">
								<Link to="">
									<FaIcons.FaSignOutAlt className="icon" />
									<span className="text nav-text">Logout</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<section className="rightbar">
				<section style={{ marginBottom: "100px" }}>
					<Outlet />
				</section>
			</section>
			<AdminFooter />

		</div>
	);
};

export default AdminContent;
